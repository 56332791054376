<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-container class="main ">
      <v-row>
        <v-col cols="12">
          <v-card elevation="0" color="rgb(242, 247, 253)">
            <v-card-title>
              Estadísticas
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-row class="container">
              <v-col>
                <v-card
                  to="/requests"
                  class="m-2 container justify-center"
                  color="white"
                >
                  <v-row align="center" justify="center">
                    <v-col cols="12" md="4" center>
                      <v-row class="p-2" justify="center">
                        <div class="imgStyle">
                          <v-img
                            max-width="60px"
                            src="../../assets/pedidos.png"
                          ></v-img>
                        </div>
                      </v-row>
                    </v-col>
                    <v-col>
                      <div
                        class="CardTitle headline font-weight-bold text-center"
                      >
                        Pedidos
                      </div>
                      <div class="pt-0 cardSubTitle text-center">
                        {{ requests.length }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col>
                <v-card to="/client" class="m-2 container" color="white">
                  <v-row>
                    <v-col cols="12" md="4" center>
                      <v-row class="p-2" justify="center">
                        <div class="text-center imgStyleGreen">
                          <v-img
                            max-width="60px"
                            src="../../assets/clientes.png"
                          ></v-img>
                        </div>
                      </v-row>
                    </v-col>
                    <v-col>
                      <div
                        class="CardTitleGreen headline font-weight-bold text-center"
                      >
                        Clientes
                      </div>
                      <div class="pt-0 cardSubTitle text-center">
                        {{ users.length }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col>
                <v-card to="/product" class="m-2 container" color="white">
                  <v-row>
                    <v-col cols="12" md="4" center>
                      <v-row class="p-2" justify="center">
                        <div class="text-center imgStyleYellow">
                          <v-img
                            max-width="60px"
                            src="../../assets/productos.png"
                          ></v-img>
                        </div>
                      </v-row>
                    </v-col>
                    <v-col>
                      <div
                        class="CardTitleYellow headline font-weight-bold text-center"
                      >
                        Productos
                      </div>
                      <div class="pt-0 cardSubTitle text-center">
                        {{ products.length }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-col dense cols="12">
        <p class="text-bold title">Últimos Pedidos</p>
      </v-col>

      <h1 class="message" v-if="resquestComputed.length === 0">
        No hay pedidos en proceso
      </h1>
      <v-card
        v-for="(item, index) in resquestComputed"
        :key="index"
        class="p-2 m-2 mb-4"
      >
        <v-row>
          <v-col md="3" offset-md="0">
            <label class="mb-5 textCode">
              {{ item.idRequest }}
            </label>
            <v-spacer></v-spacer>
            <label class="mb-3 textName">{{ item.NameClient }}</label>
            <v-spacer></v-spacer>
            <label class="textPhone">{{ item.tell }}</label>
          </v-col>
          <v-col md="4" offset-md="1">
            <p class="text-center">
              Estado del pedido
            </p>
            <v-card elevation="0" class="p-1 text-center" color="orange" dark>
              <v-icon size="25" left>{{
                item.status == "Proceso" ? "query_builder" : "moped"
              }}</v-icon>
              <span>{{ item.status }}</span>
            </v-card>
          </v-col>
          <v-col md="0" style="position: relative;" offset-md="2">
            <p class="textTime">HACE {{ item.created | date }} DIA</p>
            <div>
              <button
                @click.stop="requestClicked(item)"
                style="float: right;"
                class="btn btn-outline-primary text-width-bold contenedor"
              >
                <span>Ver pedido</span>
              </button>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-dialog v-model="dialog" max-width="720">
      <v-card>
        <v-card-title dense>Productos del pedido</v-card-title>
        <v-checkbox
          class="subTitle ml-6"
          v-model="updateModel"
          label="Marcar como despachado"
          aria-setsize="40"
        ></v-checkbox>
        <v-data-table
          loading-text="Cargando... por favor espere"
          :headers="headersProduct"
          :items="itemSelected.products"
          no-data-text="No hay datos disponible."
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:item.img="{ item }">
            <div class="p-2">
              <v-img
                :src="item.img"
                :alt="item.name"
                width="50px"
                height="50px"
              ></v-img>
            </div>
          </template>
        </v-data-table>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="dialog = false">
            Cancelar
          </v-btn>

          <v-btn color="green darken-1" :loading="loading" text @click="update">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Request from "../../components/Request/RequestDetail";
import { formatDateForHome } from "../../filters";
export default {
  filters: {
    date(value) {
      return formatDateForHome(value);
    },
  },
  name: "Home",
  components: {
    Request,
  },
  data() {
    return {
      overlay: false,
      loading: false,

      headersProduct: [
        {
          text: "Imagen",
          align: "start",
          sortable: false,
          value: "img",
        },

        { text: "Articulo", value: "name" },
        { text: "Sexo", value: "category" },
        { text: "Categoría", value: "subCategory" },
        { text: "Sub Categoría", value: "itemSubCategory" },
        { text: "Cantidad", value: "quantity" },
        { text: "Color", value: "color" },
      ],
      dialog: false,
      updateModel: false,
      itemSelected: [],
      load: true,
      search: "",
      requestsData: [],
    };
  },
  async beforeMount() {
    await this.loadRequests();
    this.getDataReuquests();
  },
  computed: {
    ...mapGetters(["users", "requests", "products"]),
    resquestComputed() {
      if (this.search == "") {
        return this.requestsData
          .filter((x) => x.status.toLowerCase() != "completado")
          .sort(function(a, b) {
            return new Date(a.date) - new Date(b.date);
          });
      } else {
        return this.requestsData
          .filter((x) => x.status.toLowerCase() != "completado")
          .filter((x) =>
            x.idRequest.toLowerCase().includes(this.search.toLowerCase())
          )
          .sort(function(a, b) {
            return new Date(a.date) - new Date(b.date);
          });
      }
    },
  },

  methods: {
    ...mapActions(["getRequests", "getProducts", "getUsers", "updateRequest"]),
    async loadRequests() {
      this.overlay = true;
      await this.getRequests();
      await this.getUsers();
      await this.getProducts();

      this.overlay = false;
    },
    async update() {
      if (!this.updateModel) {
        this.dialog = false;
        return;
      }
      this.loading = true;
      this.itemSelected.status = "En camino";
      await this.updateRequest(this.itemSelected);
      await this.getRequests();
      this.dialog = false;
      this.loading = false;
      this.updateModel = false;
      this.$emit("show-snackbar", "Se ha actualizado el pedido");
    },

    requestClicked(item) {
      this.itemSelected = item;
      this.dialog = true;
    },
    getDataReuquests() {
      this.requests.forEach((element) => {
        if (element.status == "Proceso") {
          var user = this.users.find((u) => u.uid == element.userId);
          this.requestsData.push({
            idRequest: element.idRequest,
            userId: element.userId,
            products: element.products,
            status: element.status || "",
            NameClient: user.nameInCharge || "",
            tell: user.tell,
            date: element.created.seconds,
            created: element.created,
          });
        }
      });
    },
  },
};
</script>
<style lang="css">
.CardTitle {
  color: rgb(13, 110, 253);
}
.CardTitleGreen {
  color: #6ebe6e;
}
.CardTitleYellow {
  color: rgb(255, 196, 0);
}
.cardSubTitle {
  color: black;
  font-weight: bold;
  font-size: 1.2em;
}
.main {
  background-color: rgb(242, 247, 253);
}
.textCode {
  color: rgb(13, 110, 253);
  font-weight: bold;
  font-size: 1em;
}
.textName {
  font-size: 1.2em;
  font-weight: bold;
}
.textPhone {
  font-size: 1em;
  color: gray;
}
.textTime {
  font-size: 0.8em;
  color: gray;
  text-align: right;
}
.title {
  color: black;
  font-size: 1.2em;
  font-weight: bold;
}
.subTitle {
  color: black;
  font-size: 1em;
  font-weight: bold;
}
.imgStyle {
  background-color: rgba(13, 109, 253, 0.336);
  border-radius: 50%;
  padding: 10px;
  min-width: 60px;
  max-width: 70px;
}
.imgStyleGreen {
  background-color: rgba(0, 212, 11, 0.315);
  border-radius: 50%;
  padding: 10px;
  max-width: 70px;
  min-width: 60px;
}
.imgStyleYellow {
  background-color: rgba(255, 196, 0, 0.288);
  border-radius: 50%;
  padding: 10px;
  max-width: 70px;
  min-width: 60px;
}
.message {
  font-size: 2em;
  text-align: center;
}
.contenedor {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>
