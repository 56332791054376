<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">
          Use Google's location service?
        </v-card-title>

        <v-card-text>
          Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialog = false">
            Disagree
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      text: "",
      dialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getCartByUser", "totalAmount"]),
  },
  methods: {
    ...mapActions(["removeProductFromCart", "createRequest"]),
    async remove(id) {
      await this.removeProductFromCart(id);
    },
    async sendRequest() {
      try {
        this.loading = true;
        await this.getCartByUser.map(async (element) => {
          element.note = this.text;
          element.idRequest =
            "SKU" + Math.floor(Math.random() * (1000 - 1000 + 1000) + 1000);
          var result = await this.createRequest(element);
          if (result == 500) {
            this.$emit("show-snackbar", "Error al realizar el pedido");
            return;
          }
          await this.remove(element.id);
        });
        this.$emit("show-snackbar", "Se ha realizado el pedido");
        this.loading = false;
      } catch (e) {
        this.$emit("show-snackbar", "Error al realizar el pedido");
      }
    },
  },
  mounted() {
    this.$root.$on("toggle-drawer", () => {
      this.dialog = !this.dialog;
    });
  },
};
</script>
<style lang="css">
.main {
  overflow: hidden !important;
  max-height: 100%;
}
.listStyle {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 350px;
}
.textArea {
  max-height: 4em;
}
.carTitle {
  color: white !important;
  background-color: black;
}
.itemTitle {
  color: black;
  padding-bottom: 10px;
}
.toLeft {
  float: left;
}
.totalPrice {
  text-align: right;
  font-size: 0.8em;
}
.center {
  display: block;
  margin: auto;
}
.row1 {
  color: gray;
  font-size: 1em;
  padding-bottom: 15px;
  padding-top: 10px;
}
.row2 {
  margin: 15px;
  font-size: 1em;
  color: black;
}
.itemInfo {
  vertical-align: middle;
}
.vertical-center {
  padding-top: 10%;
  padding-left: 2%;
  padding-right: 2%;
}
.buttonStyle {
  color: red;
}
.message {
  font-size: 2em;
  text-align: center;
}
</style>
